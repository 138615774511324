import React, { useEffect, useState } from "react";
import "./Team.css";
import ava_M from "../../Assets/WhoWeAre/M_.png";
import ava_F from "../../Assets/WhoWeAre/F_.png";

const Team = () => {
  const list = [
    {
      name: "Abinesh S",
      role: "DevOps Trainee",
      profile: "",
      gender: "M",
    },
    {
      name: "Abisha M",
      role: "Frontend Developer",
      profile: "",
      gender: "F",
    },
    {
      name: "Abisha S",
      role: "Technical Lead, Frontend Developer",
      profile: "",
      gender: "F",
    },
    {
      name: "Arsath Mohammed F",
      role: "Data Analyst Trainee",
      profile: "",
      gender: "M",
    },
    {
      name: "Asiba A",
      role: "Social Media Analyst",
      profile: "",
      gender: "F",
    },
    {
      name: "Barwin Raj E",
      role: "Backend Trainee",
      profile: "",
      gender: "M",
    },
    {
      name: "Brinci N V",
      role: "Frontend Developer",
      profile: "",
      gender: "F",
    },
    {
      name: "Dhinesh N",
      role: "Business Development Executive",
      profile: "",
      gender: "M",
    },
    {
      name: "Edwin Edison J",
      role: "Head of Marketing",
      profile: "",
      gender: "M",
    },
    {
      name: "Irine Saranya S",
      role: "Frontend Trainee",
      profile: "",
      gender: "F",
    },
    {
      name: "Jayasree Ramalekshmi E",
      role: "Frontend Trainee",
      profile: "",
      gender: "F",
    },
    {
      name: "Jenish J",
      role: "Backend Developer",
      profile: "",
      gender: "M",
    },
    {
      name: "Jesu Vijith",
      role: "Backend Developer",
      profile: "",
      gender: "M",
    },
    {
      name: "Joseph Samson J R",
      role: "UI/UX Designer",
      profile: "",
      gender: "M",
    },
    {
      name: "Kishore Kumar S",
      role: "Backend Trainee",
      profile: "",
      gender: "M",
    },
    {
      name: "Preethi JP",
      role: "Frontend Trainee",
      profile: "",
      gender: "F",
    },
    {
      name: "Pravin R",
      role: "Technical Lead, Frontend Developer",
      profile: '',
      gender: "M",
    },
    {
      name: "Rajanarayanan P",
      role: "Backend Trainee",
      profile: "",
      gender: "M",
    },
    {
      name: "Renjith R",
      role: "Network Engineer",
      profile: "",
      gender: "M",
    },
    {
      name: "Sahaya Vineet S",
      role: "Frontend Trainee",
      profile: "",
      gender: "F",
    },
    {
      name: "Selva Nayagam M",
      role: "Backend Trainee",
      profile: "",
      gender: "M",
    },
    {
      name: "Shaji P",
      role: "Technical Lead, Frontend Developer",
      profile: "",
      gender: "F",
    },
    {
      name: "Sowntharya E",
      role: "Digital Marketing Trainee",
      profile: "",
      gender: "F",
    },
    {
      name: "Sreejith T S",
      role: "UI/UX Designer",
      profile: "",
      gender: "M",
    },
    {
      name: "Sukesh Tony G",
      role: "Backend Developer",
      profile: "",
      gender: "M",
    },
    {
      name: "Surya N",
      role: "Frontend Trainee",
      profile: "",
      gender: "M",
    },
    {
      name: "Swarna Tharaniya P K",
      role: "HR Administrator",
      profile: "",
      gender: "F",
    },
    {
      name: "Yuvarani R",
      role: "Business Analyst",
      profile: "",
      gender: "F",
    },
  ];

  const [ourTeam, setOurTeam] = useState([]);

  useEffect(() => {
    const sortedData = list?.sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });

    setOurTeam(sortedData);
  }, []);

  return (
    <>
      <div className="pa_TB aln_Pos">
        <div className="max_Width w-full flex flex_D g_25">
          <h2 className="w-full tiTTle flex text_B">What We Believe</h2>
          <div className="ti_SubTTle">
            The company is more important than the journey or destination. So we
            make sure our clients know that the entire Althi Solutions team is
            behind them. 24/7 never stopped us from responding to challenges for
            our clients. Stop by anytime to see our team in action!
          </div>

          <div className="grid_T max_Width">
            {ourTeam?.map((item, index) => (
              <div key={index} className="team_Staff">
                <img
                  src={
                    item?.profile
                      ? item?.profile
                      : item?.gender === "M"
                      ? ava_M
                      : ava_F
                  }
                  className="w-full"
                />
                <div className="w-full flex flex_D g_15">
                  <div className="t_Name">{item?.name}</div>
                  <div className="t_Role">{item?.role}</div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Team;
