import React, { useState } from "react";
import { Card } from "primereact/card";
import { Paginator } from "primereact/paginator";
import "./BlogSection.scss";
import { usePosts } from "../../custom-hooks";
import rightArrow from "../../Media/leftarrow.svg";

const BlogSection = () => {
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(6);

  const [blogs] = usePosts();

  const sortedBlogs = blogs
    .sort((a, b) => new Date(b.sys.createdAt) - new Date(a.sys.createdAt))
    .slice(first, first + rows);

  const onPageChange = (event) => {
    setFirst(event.first);
    setRows(event.rows);
  };

  return (
    <>
      <div className="blog-content-section aln_Pos">
        <div className="grid m-0 p-0">
          <div className="col-12 m-0 p-0">
            <Card className="flex flex-column blog-card-section flex-wrap pa_25_">
              <div className="max_Width">
                <div>
                  <h2 className="flex blog-section-heading">Althi Blog</h2>
                </div>

                <div
                  className="flex align-items-center card-content flex_W g_25 w-full"
                  style={{ justifyContent: "flex-start" }}
                >
                  {/* {sortedBlogs.map((blog, index) => (
                    <div key={index} className="bloG pa_Un">
                      <Card className="new">
                        <div className="blog-img">
                          <img
                            src={blog.fields.blogImage[0].fields.file.url}
                            alt={blog.fields.blogTitle}
                          />
                        </div>

                        <div className="blog-text">
                          <div className="pt-3">
                            <span>
                              {blog.fields.blogAuthor} |{" "}
                              {blog.fields.createdDate}
                            </span>{" "}
                            <br />
                          </div>

                          <div className="pt-3">
                            <p className="blogs-heading">
                              {blog.fields.blogTitle}
                              <a
                                href={`/blog/${blog.sys.id}`}
                                aria-label="Read more"
                              >
                                <i className="pi pi-arrow-right" />
                              </a>
                            </p>
                          </div>
                        </div>
                      </Card>
                    </div>
                  ))} */}

                  {sortedBlogs.map((blog, index) => (
                    <div className="blOG_Item flex flex_D g_15">
                      <img
                        className="blOG_"
                        src={blog.fields.blogImage[0].fields.file.url}
                        alt={blog.fields.blogTitle}
                      />
                      <div className="w-full blOG_L">
                        {" "}
                        {blog.fields.blogAuthor} | {blog.fields.createdDate}
                      </div>
                      <div className="w-full text_W blOG_V">
                        {blog.fields.blogTitle}{" "}
                        <a
                          href={`/blog/${blog.sys.id}`}
                          aria-label="Read more"
                          className="bloG_Nav"
                        >
                          <img
                            className="whatwedo-small-arrow"
                            src={rightArrow}
                            alt=""
                            width="16px"
                          />
                        </a>
                      </div>
                    </div>
                  ))}
                </div>

                {blogs.length > rows && (
                  <Paginator
                    first={first}
                    rows={rows}
                    totalRecords={blogs.length}
                    rowsPerPageOptions={[6, 12, 18]}
                    onPageChange={onPageChange}
                    template="PrevPageLink PageLinks NextPageLink"
                    className="pa_TB"
                  />
                )}
              </div>
            </Card>
          </div>
        </div>
      </div>
      {/* </div> */}
    </>
  );
};

export default BlogSection;
