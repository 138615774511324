// import React, { useState } from "react";
// import ISO from "../Assets/ContactUs/ISO.png";
// import DPIIT from "../Assets/ContactUs/DPIIT.png";
// import "./FooterPage.scss";
// import { InputText } from "primereact/inputtext";
// import { Divider } from "primereact/divider";
// import whitelogo from "../Media/whitelogo.svg";
// import { ImFacebook } from "react-icons/im";
// import { FaLinkedinIn } from "react-icons/fa";
// import { AiOutlineInstagram } from "react-icons/ai";
// import { BsTwitterX } from "react-icons/bs";
// import axios from "axios";
// import "primeicons/primeicons.css";

// const FooterPage = () => {
//   const [formData, setFormData] = useState({
//     email: "",
//   });

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({
//       ...formData,
//       [name]: value,
//     });
//   };

//   const handleSubmit = (e) => {
//     if (formData.email.trim() === "") {
//       alert("Please enter a valid email address");
//     }

//     e.preventDefault();

//     const apiURL = process.env.REACT_APP_CONTACTUS_HOST;

//     axios
//       .post(apiURL, formData, {
//         headers: {
//           "Content-Type": "application/json",
//         },
//       })
//       .then(() => {
//         alert("Thank you for subscribing to our newsletter.");
//       });
//   };

//   return (
//     <>
//       <div className="footer-section-black aln_Pos">
//         <div className="w-full">
//           <div className="footer-section" style={{ padding: "0 25px" }}>
//             <div className="footer-links max_Width">
//               <div className="logosection">
//                 <div className="footer-icons">
//                   <div className="socialmedia-icon">
//                     <a
//                       href="https://www.facebook.com/AlthiSolutions"
//                       className="social-media"
//                       target="_blank"
//                       rel="noopener noreferrer"
//                     >
//                       <ImFacebook />
//                     </a>
//                   </div>

//                   <div className="socialmedia-icon">
//                     <a
//                       href="https://twitter.com/AlthiSolutions/"
//                       className="social-media"
//                       target="_blank"
//                       rel="noopener noreferrer"
//                     >
//                       <BsTwitterX />
//                     </a>
//                   </div>

//                   <div className="socialmedia-icon">
//                     <a
//                       href="https://www.instagram.com/althisolutions/"
//                       className="social-media"
//                       target="_blank"
//                       rel="noopener noreferrer"
//                     >
//                       <AiOutlineInstagram />
//                     </a>
//                   </div>

//                   <div className="socialmedia-icon">
//                     <a
//                       href="https://in.linkedin.com/company/althisolutions"
//                       className="social-media"
//                       target="_blank"
//                       rel="noopener noreferrer"
//                     >
//                       <FaLinkedinIn />
//                     </a>
//                   </div>
//                 </div>

//                 <img className="footerlogo" src={whitelogo} alt="White Logo" />
//               </div>

//               <div className="quick-links col m-3">
//                 <h4>COMPANY</h4>
//                 <ul>
//                   <li>
//                     {" "}
//                     <a href="/product">Product</a>{" "}
//                   </li>
//                   <li>
//                     {" "}
//                     <a href="/blog">Blog</a>{" "}
//                   </li>
//                   <li>
//                     {" "}
//                     <a href="/contactus">Support</a>{" "}
//                   </li>
//                 </ul>
//               </div>

//               <div className="quick-links col  m-3">
//                 <h4>CONTACT US</h4>
//                 <ul>
//                   <li>info@althisolutions.com</li>
//                   <li>+91 04652 236 359&nbsp;&#40;INDIA&#41;</li>
//                   <li>+1 959-666-3350&nbsp;&#40;US&#41;</li>
//                   <li>hr@althisolutions.com</li>
//                 </ul>
//               </div>

//               <div className="quick-links footer-news col m-3">
//                 <h4>STAY UP TO DATE</h4>
//                 <p className="mb-3">Subscribe to our newsletter.</p>
//                 <span className="p-input-icon-right">
//                   <i className="pi pi-arrow-right" onClick={handleSubmit} />
//                   <InputText
//                     name="email"
//                     type="email"
//                     placeholder="Email"
//                     value={formData.email}
//                     onChange={handleChange}
//                   />
//                 </span>
//               </div>
//             </div>
//           </div>

//           <Divider type="solid" />

//           <div className="w-full" style={{ padding: "0 25px" }}>
//             <div className="footer-sec max_Width">
//               <div className="policy-section">
//                 <a href="#">
//                   Althi Solutions Pvt Ltd © 2024 All rights reserved
//                 </a>
//                 {/* <a href="#">Cookies Policy</a> */}
//                 <a href="/termsandconditions">Terms & Conditions</a>
//                 <a href="/privacypolicy">Privacy Policy</a>
//               </div>
//               <div className="logo-sec">
//                 <div className="legals col m-3">
//                   <img src={ISO} alt="ISO Logo" />
//                 </div>

//                 <div className="legals col m-3">
//                   <img src={DPIIT} alt="DPIIT Logo" />
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default FooterPage;
import React from "react";
import "./FooterPage.css";
import emblemOne from "../Media/emblem1.svg";
import emblemTwo from "../Media/emblem2.svg";
import facebookWhite from "../Media/facebookwhite.svg";
import instagramWhite from "../Media/instagramwhite.svg";
import linkedinWhite from "../Media/linkedinwhite.svg";
import xWhite from "../Media/xwhite.svg";

const Footersec = () => {
  return (
    <section className="footersec">
      <div className="top-footer">
        <div className="top-footer-heading">
          <p className="building-on-trust">Building On Trust</p>
        </div>
        {/* <div className="top-footer-button">
          <button className="footer-small-button">
            <span>Request a Quote</span>
            <img className="footer-small-arrow" src={footerArrow} alt="" />
          </button>
        </div> */}
      </div>
      <div className="mid-footer">
        <div className="company-section">
          <div className="company-heading">
            <p className="company-heading-text">Company</p>
          </div>
          <div className="company-sub-heading">
            <ul className="list-u">
              <li>
                <a className="list-o" href="/whoWeAre">
                  Who We Are
                </a>
              </li>
              <li>
                <a className="list-o" href="/blog">
                  Blog
                </a>
              </li>
              <li>
                <a className="list-o" href="/contactus">
                  Support
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="provider-section">
          <div className="provider-heading">
            <p className="provider-heading-text">Services</p>
          </div>
          <div className="provider-sub-heading">
            <ul className="list-u">
              <li>
                <a className="list-o" href="/contactus">
                  Enterprise Solutions
                </a>
              </li>
              <li>
                <a className="list-o" href="/contactus">
                  Mobile App Development
                </a>
              </li>
              <li>
                <a className="list-o" href="/contactus">
                  Product & Customer Support
                </a>
              </li>
              <li>
                <a className="list-o" href="/contactus">
                  Web Design & Development
                </a>
              </li>
              <li>
                <a className="list-o" href="/contactus">
                  Consulting Services
                </a>
              </li>
              <li>
                <a className="list-o" href="/contactus">
                  Digital Marketing
                </a>
              </li>
              <li>
                <a className="list-o" href="/contactus">
                  Resource to Hire
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="connect-section">
          <div className="connect-heading">
            <p className="connect-heading-text">Connect</p>
          </div>
          <div className="connect-sub-heading">
            <ul className="list-u">
              <li>
                <a className="list-o">
                  India&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  : &nbsp;&nbsp;+91 04652 236 359
                </a>
              </li>
              <li>
                <a className="list-o">
                  US&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  : &nbsp;&nbsp;+1 959-666-3350
                </a>
              </li>
              <li>
                <a className="list-o">
                  General Information&nbsp;&nbsp;&nbsp;&nbsp; :
                  &nbsp;&nbsp;info@althisolutions.com
                </a>
              </li>
              <li>
                <a className="list-o">
                  Sales
                  Inquiries&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  : &nbsp;&nbsp;sales@althisolutions.com
                </a>
              </li>
              <li>
                <a className="list-o">
                  HR
                  Inquiries&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  : &nbsp;&nbsp;hr@althisolutions.com
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="bot-footer">
        <div className="white-socialmedia">
          <div className="white-facebook">
            <a
              className="list-o"
              href="https://www.facebook.com/AlthiSolutions"
            >
              <img
                className="white-facebook-icon"
                src={facebookWhite}
                alt="facebook"
              />
            </a>
          </div>
          <div className="white-linkedin">
            <a
              className="list-o"
              href="https://in.linkedin.com/company/althisolutions"
            >
              <img
                className="white-linkedin-icon"
                src={linkedinWhite}
                alt="linkedin"
              />
            </a>
          </div>
          <div className="white-instagram">
            <a
              className="list-o"
              href="https://www.instagram.com/althi_solutions"
            >
              <img
                className="white-instagram-icon"
                src={instagramWhite}
                alt="instagram"
              />
            </a>
          </div>
          {/* <div className="white-x">
            <a className="list-o" href="">
              <img className="white-x-icon" src={xWhite} alt="" />
            </a>
          </div> */}
        </div>
        <div className="right-reserved">
          <p className="right-reserved-text">
            Althi solutions Pvt Ltd &copy; 2025 All Rights Reserved
          </p>
        </div>
        <div className="terms">
          <div className="emblem-one">
            <img src={emblemOne} alt="terms and conditions" />
          </div>
          <div className="emblem-one-text">
            <a href="/termsandconditions">
              <p className="terms-text">Terms & Condition</p>
            </a>
          </div>
        </div>
        <div className="privacy-policy">
          <div className="emblem-two">
            <img src={emblemTwo} alt="privacy policy" />
          </div>
          <div className="emblem-two-text">
            <a href="/privacypolicy">
              <p className="privacy-text">Privacy Policy</p>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Footersec;
