import React, { useEffect, useState } from "react";
import { gsap } from "gsap";
import "./whatwedo.css";
import rightArrow from "../../Media/leftarrow.svg";
import { useNavigate } from "react-router-dom";

const sections = [
  { title: "Cyber Security", description: "Protecting your digital assets." },
  { title: "Data Analytics", description: "Turning data into insights." },
  { title: "AI Solutions", description: "Innovative AI-driven solutions." },
  { title: "Cloud Computing", description: "Secure cloud-based services." },
  { title: "Sustainability", description: "Building a greener future." },
];

const Whatwedo = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    const timeline = gsap.timeline({ repeat: -1 });

    sections.forEach((_, index) => {
      timeline.to(".content-one", {
        opacity: 0,
        scale: 0.95,
        duration: 0.8,
        ease: "power2.inOut",
        delay: 2, // Time each section stays visible
        onComplete: () => {
          setCurrentIndex((prev) => (prev + 1) % sections.length);
        },
      });

      timeline.to(".content-one", {
        opacity: 1,
        scale: 1,
        duration: 0.8,
        ease: "power2.inOut",
      });
    });

    return () => timeline.kill();
  }, []);
  return (
    <section className="bgblack">
      <div className="content-one">
        <div className="cybersecurity">
          <p className="content-one-text">{sections[currentIndex].title}</p>
        </div>
        <div className="subcontent-one">
          <p className="subcontent-one-text">
            {sections[currentIndex].description}
          </p>
        </div>
        <div className="tertiary-button">
          <button className="tertiary" onClick={() => navigate("/contactus")}>
            <span className="testing">Explore more</span>
            <img className="whatwedo-small-arrow" src={rightArrow} alt="" />
          </button>
        </div>
      </div>
    </section>
  );
};

export default Whatwedo;
