import React, { useEffect } from "react";
import Nav from "../Home/navbar/nav";
import WhoWeAreBanner from "./WhoWeAreBanner/WhoWeAreBanner";
import Ambitious from "./Ambitious/Ambitious";
import WhatWeBelieve from "./WhatWeBelieve/WhatWeBelieve";
import OurPresence from "./OurPresence/OurPresence";
import { Helmet } from "react-helmet";
import Team from "./Team/Team";

const WhoWeAre = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <>
      <Helmet>
        <meta charset="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <title>Who We Are</title>
        <meta
          name="description"
          content="Business is complicated, but we guarantee it won't be boring."
        />
        <link rel="canonical" href="https://www.althisolutions.com/whoWeAre" />
        <meta name="robots" content="index, follow" />

        <meta property="og:type" content="website" />
        <meta property="og:title" content="Who We Are" />
        <meta
          property="og:url"
          content="https://www.althisolutions.com/whoWeAre"
        />
        <meta
          property="og:image"
          content="https://althisolutions.com/static/media/Team-Work.097b44dc1fe67ba053f0.jpg"
        />
        <meta
          property="og:description"
          content="Business is complicated, but we guarantee it won't be boring."
        />
        <meta property="og:site_name" content="Althi Solutions" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Who We Are" />
        <meta
          name="twitter:site"
          content="@https://www.althisolutions.com/whoWeAre"
        />
        <meta
          name="twitter:description"
          content="Business is complicated, but we guarantee it won't be boring."
        />
        <meta
          name="twitter:image"
          content="https://althisolutions.com/static/media/Team-Work.097b44dc1fe67ba053f0.jpg"
        />
      </Helmet>

      <Nav />
      <WhoWeAreBanner />
      <Ambitious />
      <Team />
      {/* <WhatWeBelieve /> */}
      <OurPresence />
    </>
  );
};

export default WhoWeAre;
