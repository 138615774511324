// import React from "react";
// import "./OurClients.scss";
// import Woody from "../../Media/woody.png";
// import Alnada from "../../Media/alnada.png";
// import aryanow from "../../Media/aryanow.png";
// import Kaladi from "../../Media/kaladi.png";
// import phi from "../../Assets/Home/phi.svg";
// import micro from "../../Assets/Home/micro.svg";
// import Slider from "react-slick";

// const OurClients = () => {
//     const images = [
//         {
//             image: Kaladi,
//         },
//         {
//             image: Alnada,
//         },
//         {
//             image: Woody,
//         },
//         {
//             image: phi,
//         },
//         {
//             image: aryanow,
//         },
//         {
//             image: micro,
//         },
//     ];

//     const settings = {
//         dots: false,
//         infinite: true,
//         slidesToShow: 6,
//         slidesToScroll: 1,
//         initialSlide: 0,
//         autoplay: true,
//         speed: 8000,
//         autoplaySpeed: 8000,
//         cssEase: "linear",
//         responsive: [
//             {
//                 breakpoint: 1400,
//                 settings: {
//                     slidesToShow: 4,
//                     slidesToScroll: 4,
//                     infinite: true,
//                     dots: false,
//                 },
//             },
//             {
//                 breakpoint: 1200,
//                 settings: {
//                     slidesToShow: 3,
//                     slidesToScroll: 3,
//                     infinite: true,
//                     dots: false,
//                 },
//             },
//             {
//                 breakpoint: 1024,
//                 settings: {
//                     slidesToShow: 2,
//                     slidesToScroll: 2,
//                     infinite: true,
//                     dots: false,
//                 },
//             },
//             {
//                 breakpoint: 600,
//                 settings: {
//                     slidesToShow: 1,
//                     slidesToScroll: 1,
//                     initialSlide: 1,
//                 },
//             },
//             {
//                 breakpoint: 480,
//                 settings: {
//                     slidesToShow: 1,
//                     slidesToScroll: 1,
//                 },
//             },
//         ],
//     };

//     return (
//         <>
//             <div className="client-section">
//                 <div className="container">
//                 <button className="ourClients-btn">Our Partners</button>
//                 <h2 className="ourClients-title">We’re Working With</h2>
//                 </div>
//                 <div className="ourClients">

//                     <Slider {...settings}>
//                         {images.map((image) => (
//                             <img className="ourClients-images" src={image.image} alt="" />
//                         ))}
//                     </Slider>
//                 </div>
//             </div>
//         </>
//     );
// };

// export default OurClients;

import React from "react";
import "./blogs.css";
import blogOne from "../../Media/blogone.jpg";
import blogTwo from "../../Media/blogtwo.jpg";
import blogThree from "../../Media/blogthree.jpg";
import smallArrow from "../../Media/smallarrow.svg";
import { GoArrowRight } from "react-icons/go";
import { useNavigate } from "react-router-dom";

const Blogs = () => {
  const navigate = useNavigate();
  return (
    <section className="blog-section">
      <div className="blog-heading">
        <p className="blogs-text">Blogs</p>
      </div>
      <div className="blog-post">
        <div className="blog-one">
          <div className="blog-image-container-one">
            <img className="blog-img-first" src={blogOne} alt="blog post one" />
          </div>
          <div className="blog-content-text-one">
            <p className="blog-sub-heading-one">Blog Series</p>
            <p className="blog-content-one">
              World Day Against Cyber Censorship
            </p>
          </div>
          <div className="button-section">
            <button className="small-arrow" onClick={() => navigate("/blog")}>
              <span className="testing">Know more</span>
              <img className=" blogs-bluearrow" src={smallArrow} alt="" />
            </button>
          </div>
        </div>
        <div className="blog-two">
          <div className="blog-image-container-one">
            <img className="blog-img-first" src={blogTwo} alt="blog post two" />
          </div>
          <div className="blog-content-text-one">
            <p className="blog-sub-heading-one">Blog Series</p>
            <p className="blog-content-one">
              Top 5 Startup Challenges and their Solutions in 2025
            </p>
          </div>
          <div className="button-section">
            <button className="small-arrow" onClick={() => navigate("/blog")}>
              <span className="testing">Know more</span>
              <img className=" blogs-bluearrow" src={smallArrow} alt="" />
            </button>
          </div>
        </div>
        <div className="blog-three">
          <div className="blog-image-container-one">
            <img
              className="blog-img-first"
              src={blogThree}
              alt="blog post three"
            />
          </div>
          <div className="blog-content-text-one">
            <p className="blog-sub-heading-one">Blog Series</p>
            <p className="blog-content-one">The Evolution of Web Development</p>
          </div>
          <div className="button-section">
            <button className="small-arrow" onClick={() => navigate("/blog")}>
              <span className="testing">Know more</span>
              <img className=" blogs-bluearrow" src={smallArrow} alt="" />
            </button>
          </div>
        </div>
      </div>
      <div className="blog-button">
        <button className="blog-small-button" onClick={() => navigate("/blog")}>
          <span className="testing">Explore more</span>
          <GoArrowRight className="blogs-blackarrow" />
        </button>
      </div>
    </section>
  );
};

export default Blogs;
