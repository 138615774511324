import React, { useEffect } from "react";
import { usePosts } from "../../custom-hooks";
import { Card } from "primereact/card";
import "../BlogSection/BlogSection.scss";
import rightArrow from "../../Media/leftarrow.svg";

const RelatedContent = ({ currentArticle }) => {
  const [blogs] = usePosts();

  const relatedArticles = blogs.filter(
    (article) => article.sys.id !== currentArticle
  );

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <div className="blog-content-section aln_Pos">
      <div className="blog-card-section pa_TB">
        <div className="w-full max_Width">
          <h2 className="related-section-heading flex">Related Articles</h2>
        </div>

        {relatedArticles.length > 0 ? (
          <ul className="card-content flex max_Width g_25">
            {relatedArticles.slice(-3).map((blog, index) => (
              <div
                key={index}
                className="bloG pa_Un"
                style={{ justifyContent: "space-between" }}
              >
                <Card className="new">
                  <div className="blog-img">
                    <img
                      src={blog.fields.blogImage[0].fields.file.url}
                      alt={blog.fields.blogTitle}
                    />
                  </div>

                  <div className="blog-text">
                    <div className="pt-3">
                      <span>
                        {blog.fields.blogAuthor} | {blog.fields.createdDate}
                      </span>{" "}
                      <br />
                    </div>

                    <div className="pt-3">
                      <p className="blogs-heading">
                        {blog.fields.blogTitle}
                        <a
                          href={`/blog/${blog.sys.id}`}
                          aria-label="Read more"
                          className="bloG_Nav"
                        >
                          <img
                            className="whatwedo-small-arrow"
                            src={rightArrow}
                            alt=""
                            width="16px"
                          />
                        </a>
                      </p>
                    </div>
                  </div>
                </Card>
              </div>
            ))}
          </ul>
        ) : (
          <p>No related articles found.</p>
        )}
      </div>
    </div>
  );
};

export default RelatedContent;
