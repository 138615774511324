import React from "react";
import "./Ambitious.scss";

const Ambitious = () => {
  return (
    <>
      <div className="w-full aln_Pos b_Bg flex" style={{ minHeight: "500px" }}>
        <div className="pa_TB max_Width flex flex_D g_25 ">
          <h2 className="w-full tiTTle flex text_W">
            Building Ambitious Future
          </h2>
          <div className="ti_SubTTle text_W">
            Through Innovation and collective knowledge Althi Solutions is an IT
            services, consulting, and business solutions organization. We serve
            both big and small businesses in various industries such as
            manufacturing, healthcare, finance, education, etc.
          </div>
        </div>
      </div>
    </>
  );
};

export default Ambitious;
