import { useParams } from "react-router-dom";
import Nav from "../Home/navbar/nav";
import "./ProductPage.scss";

import banner1 from "../Assets/WhatWeDo/portrait-indigenous-person-integrating-society.webp";
import banner2 from "../Assets/WhatWeDo/business-person-futuristic-business-environment.webp";
import banner3 from "../Assets/WhatWeDo/business-person-futuristic-business-environment (1).webp";

import { Skeleton } from "primereact/skeleton";
import { FaRegCheckCircle } from "react-icons/fa";

import vCheckLogo from "../Assets/WhatWeDo/vcheck-logo.svg";
import freeSecure from "../Assets/WhatWeDo/freeSecure.svg";
import easyIntegration from "../Assets/WhatWeDo/easyIntegration.svg";
import privacyAssured from "../Assets/WhatWeDo/privacyAssured.svg";

import exaanLogo from "../Assets/WhatWeDo/exaanLogo.svg";
import forex from "../Assets/WhatWeDo/forex.svg";
import regulatoryCompliance from "../Assets/WhatWeDo/regulatoryCompliance.svg";
import treasury from "../Assets/WhatWeDo/treasury.svg";
import accounting from "../Assets/WhatWeDo/accounting.svg";
import remittance from "../Assets/WhatWeDo/remittance.svg";

import acaiaLogo from "../Assets/WhatWeDo/acaiaLogo.svg";
import dataPrivacy from "../Assets/WhatWeDo/dataPrivacy.svg";
import customSanctionList from "../Assets/WhatWeDo/customSanctionList.svg";
import proactiveAudit from "../Assets/WhatWeDo/proactiveAudit.svg";
import amlChecks from "../Assets/WhatWeDo/amlChecks.svg";
import potentRiskRating from "../Assets/WhatWeDo/potentRiskRating.svg";

import ctaImage from "../Assets/WhatWeDo/portrait-woman-customer-service-worker.webp";

import vCheck_Img from "../Assets/Product/VCheck_Img.png";
import acaia_IMG from "../Assets/Product/acaia_IMG.png";
import Exaan_Img from "../Assets/Product/Exaan_Img.png";

const products = [
  {
    id: "VCheck",
    name: "VCheck",
    image: banner1,
    content: "Secure your files from malware!",
    subContent: "Detect malware and viruses in files using our free API keys.",
    productName: "VCheck",
    productImg: vCheck_Img,
    description:
      "VCheck offers hassle-free malware scanning. Our service is free and secure, and respects your privacy by deleting files automatically after 24 hours. Whether you're an individual or a company, integrating our robust upload and download APIs into your website is simple and effective, ensuring your files are always protected.",
    productLogo: vCheckLogo,
    features: [
      { title: "Free And Secure", icon: freeSecure, active: false },
      { title: "Easy Integration", icon: easyIntegration, active: true },
      { title: "Privacy Assured", icon: privacyAssured, active: false },
    ],
    link: "https://www.vcheckk.com/",
    CTAContent: "Register Now To Get Your Unique API Keys.",
  },
  {
    id: "Exaan",
    name: "Exaan",
    image: banner2,
    content: "A Cosmic Financial Solutions",
    subContent: "Seamless transaction and collaborative ecosystem.",
    productName: "Exaan",
    productImg: Exaan_Img,
    description:
      "As an all-in-one financial management solution, Exaan creates an impact in every domain of the financial service industry. Exaan is designed to help financial services institutions gain operational efficiency, enhance end-customer experience, and embrace innovative technologies.",
    subdescription:
      "Made available on the cloud, white labeled, also offered as SaaS, and built on a strong digital core, Exaan is visioned on creating seamless digital financial journeys, the products offer advanced breadth and depth of functionality and are built on a modern, deep technology stack.",
    productLogo: exaanLogo,
    features: [
      { title: "Forex", icon: forex, active: false },
      {
        title: "Regulatory Compliance",
        icon: regulatoryCompliance,
        active: true,
      },
      { title: "Treasury", icon: treasury, active: false },
      { title: "Accounting", icon: accounting, active: true },
      { title: "Remittance", icon: remittance, active: false },
    ],
    CTAContent: "A Solution That is Loved In Every Universe.",
    CTADescription: "It's not strange to deserve it.",
  },
  {
    id: "Acaia",
    name: "Acaia",
    image: banner3,
    content: "Anti Money Laundering Suite",
    subContent: "Acaia has you covered when it comes to regulatory compliance",
    productName: "An Absolute AML Solution",
    productImg: acaia_IMG,
    description:
      "Financial institutions can be at ease when it comes to regulatory compliance. Acaia is an AML suite to enhance your defense against Fraud & Money Laundering. Acaia ensures that financial institutions derive high performance for their mission to prevent illicit money transfers.",
    subdescription:
      "Made available on the cloud, white labeled, also offered as SaaS, and built on a strong digital core, Acaia helps banks and financial services institutions become more agile and resistant to terrorist funding and corruption.",
    productLogo: acaiaLogo,
    features: [
      { title: "Data Privacy", icon: dataPrivacy, active: false },
      { title: "Custom Sanction list", icon: customSanctionList, active: true },
      { title: "Proactive Audit", icon: proactiveAudit, active: false },
      { title: "AML Checks", icon: amlChecks, active: true },
      { title: "Potent Risk Rating", icon: potentRiskRating, active: false },
    ],
    link: "https://acaia.us/",
    CTAContent: "Stress-free compliance is within reach.",
    CTADescription: "Partner with us to be fully compliant through AI.",
  },
];

const ProductPage = () => {
  const { id } = useParams();
  if (!Array.isArray(products)) {
    return <div>Error: products is not an array</div>;
  }

  const product = products.find((item) => item.id === id);

  if (!product) {
    return <div>Product not found</div>;
  }

  return (
    <>
      <Nav />

      <div className="w-full aln_Pos" style={{ background: "#131313" }}>
        <img
          src={product?.image}
          alt={product?.productName}
          className="img_Pro"
        />

        <div
          className="flex flex_D text_W w-full"
          style={{ position: "absolute", top: "200px" }}
        >
          <h2 className="conT">
            {product?.content}
            <span>.</span>
          </h2>
          <p className="conT_Sub">{product?.subContent}</p>
        </div>

        <div className="pa_TB">
          <div className="w-full max_Width pa_TB proD_Item flex flex_D g_25">
            <div className="flex flex_D g_25">
              <div className="text_W flex tiTTle">{product?.productName}</div>
              <div className="ti_SubTTle">{product?.description}</div>
              {product?.subdescription ? (
                <div className="ti_SubTTle">{product?.subdescription}</div>
              ) : null}
            </div>
            <img src={product?.productImg} className="prO_Img" />
          </div>
        </div>

        <div
          className="w-full flex g_25 pa_TB max_Width flex_W"
          style={{ justifyContent: "space-between" }}
        >
          <div
            className="flex flex_D g_15"
            style={{ alignItems: "flex-start" }}
          >
            <h2 className="text_W">{product?.CTAContent}</h2>
            <p className="text_W">{product?.CTADescription}</p>

            <div
              className="w-full flex g_25 flex_W"
              style={{ justifyContent: "flex-start" }}
            >
              <button className="cta_Btn flex" style={{ padding: "0 25px" }}>
                <a href="/contactUs" className="text_W a_Btn">
                  CONTACT US
                </a>
              </button>

              <button
                className="cta_Btn"
                style={{
                  padding: "0 25px",
                  border: "2px solid #ee781c",
                  background: "none",
                }}
              >
                <a
                  href={product?.link}
                  className="a_Btn text_W"
                  target="_blank"
                  rel="noreferrer"
                >
                  VISIT US
                </a>
              </button>
            </div>
          </div>
          <img src={ctaImage} alt="Customer Service" className="ctA_Img" />
        </div>
      </div>
    </>
  );
};

export default ProductPage;
