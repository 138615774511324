import Nav from "../Home/navbar/nav";
import SideTab from "./SideTab/SideTab";

const TermsAndConditions = () => {
  return (
    <>
      <Nav />
      <SideTab />
    </>
  );
};

export default TermsAndConditions;
