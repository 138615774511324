import React from "react";
import "./hero.css";

const Hero = () => {
  return (
    <section className="herosection">
      <h1 className="heading">
        First Step In your <span className="gradient-text">Digital</span>{" "}
        Transformation
      </h1>
      <p className="subheading">
        We aspire to create a world where technology enhances lives, turning
        this vision into reality.
      </p>
    </section>
  );
};

export default Hero;
