import React from "react";
import "./clients.css";
import clientOne from "../../Media/client1.svg";
import clientTwo from "../../Media/client2.svg";
import clientThree from "../../Media/client3.svg";
import clientFour from "../../Media/client4.svg";
import clientFive from "../../Media/client5.svg";
import clientSix from "../../Media/client6.svg";
import clientSeven from "../../Media/client7.svg";
import clientEight from "../../Media/client8.svg";

const Clients = () => {
  return (
    <section className="client-section">
      <div className="client-brief">
        <div className="client-heading">
          <p className="client-heading-text">Our Clients</p>
        </div>
        <div className="client-details">
          <p className="client-details-text">
            We are proud to partner with industry leaders and innovative
            businesses worldwide.
          </p>
        </div>
      </div>
      <div className="client-logos">
        <div className="clients-logo1">
          <img
            className="logo-one"
            src={clientOne}
            alt="splunk PHI/PII Removal solution Inheritance"
          />
        </div>
        <div className="clients-logo2">
          <img className="logo-two" src={clientTwo} alt="Kalaadi King" />
        </div>
        <div className="clients-logo3">
          <img className="logo-three" src={clientThree} alt="arya" />
        </div>

        <div className="clients-logo4">
          <img className="logo-four" src={clientFour} alt="al nada exchange" />
        </div>
        <div className="clients-logo5">
          <img className="logo-five" src={clientFive} alt="woody" />
        </div>
        <div className="clients-logo6">
          <img
            className="logo-six"
            src={clientSix}
            alt="securing internal microservice"
          />
        </div>
        <div className="clients-logo7">
          <img
            className="logo-seven"
            src={clientSeven}
            alt="noorul islam college of arts and science"
          />
        </div>
        <div className="clients-logo8">
          <img className="logo-eight" src={clientEight} alt="prime electriks" />
        </div>
      </div>
    </section>
  );
};

export default Clients;
