import React, { useEffect, useState } from "react";
import "./ContactUs.scss";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import axios from "axios";
import { AiOutlineCheckCircle } from "react-icons/ai";
import Nav from "../Home/navbar/nav";
import { Helmet } from "react-helmet";

const ContactUs = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    organization: "",
    region: "",
    message: "",
  });

  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();

    const apiURL = process.env.REACT_APP_CONTACTUS_HOST;

    const payload = {
      message: {
        fullName: formData.firstName + " " + formData.lastName,
        email: formData.email,
        organization: formData.organization,
        region: formData.region,
        message: formData.message,
      },
    };

    if (
      formData.firstName &&
      formData.organization &&
      formData.message &&
      formData.email &&
      formData.region
    ) {
      axios
        .post(apiURL, payload, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          setShowSuccessMessage(true);

          setTimeout(() => {
            setShowSuccessMessage(false);
            setFormData({
              firstName: "",
              lastName: "",
              email: "",
              organization: "",
              region: "",
              message: "",
            });
          }, 3000);
        });
    }
  };

  return (
    <>
      <div className="w-full ">
        <Helmet>
          <meta charset="utf-8" />
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <title>Contact Us</title>
          <meta
            name="description"
            content="With unique solutions, we create transformation in every industry."
          />
          <link
            rel="canonical"
            href="https://www.althisolutions.com/contactus"
          />
          <meta name="robots" content="index, follow" />

          <meta property="og:type" content="website" />
          <meta property="og:title" content="Contact Us" />
          <meta
            property="og:url"
            content="https://www.althisolutions.com/contactus"
          />
          <meta
            property="og:image"
            content="https://althisolutions.com/static/media/Contactus-bg.5453882e5eb86b6b57e7.jpg"
          />
          <meta
            property="og:description"
            content=" With unique solutions, we create transformation in every industry."
          />

          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content="Contact Us" />
          <meta
            name="twitter:site"
            content="@https://www.althisolutions.com/contactus"
          />
          <meta
            name="twitter:description"
            content="With unique solutions, we create transformation in every industry."
          />
          <meta
            name="twitter:image"
            content="https://althisolutions.com/static/media/Contactus-bg.5453882e5eb86b6b57e7.jpg"
          />
        </Helmet>

        <Nav />

        <div className="contactus-section-banner aln_Pos">
          <div className="contact-overlay">
            {/* <div className='container'> */}
            <div
              className="contactus-section-overlay flex"
              style={{ height: "auto", padding: "134px 25px" }}
            >
              <div className="max_Width w-full">
                <div
                  className="contactus-section-banner-content-section flex g_25"
                  style={{ justifyContent: "space-between" }}
                >
                  <div className="contactus-section-banner-content flex flex_D g_25">
                    <h2 className="w-full">
                      REQUEST FOR SERVICE<span>.</span>
                    </h2>
                    <p className="ti_SubTTle text_W">
                      Our unique solutions create transformation across every
                      industry, and we're excited to make solutions that best
                      suit your business. Tell us a little of yourself, and
                      let's set things in motion.
                    </p>
                  </div>

                  <div className="contactus-section-banner-form">
                    <div className="contactus-form">
                      <h2>Let's Talk</h2>
                      <form onSubmit={handleSubmit}>
                        <div className="field col">
                          <label>First Name</label>
                          <InputText
                            type="text"
                            name="firstName"
                            value={formData.firstName}
                            onChange={handleChange}
                            className="p-inputtext-sm w-full"
                            required
                          />
                        </div>
                        <div className="field col">
                          <label>Last Name</label>
                          <InputText
                            type="text"
                            name="lastName"
                            value={formData.lastName}
                            onChange={handleChange}
                            className="p-inputtext-sm w-full"
                          />
                        </div>
                        <div className="field col">
                          <label>Email</label>
                          <InputText
                            type="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            className="p-inputtext-sm w-full"
                            required
                          />
                        </div>
                        <div className="flex col w-max">
                          <div className="field col-6 pl-0">
                            <label>Organization</label>
                            <InputText
                              type="text"
                              name="organization"
                              value={formData.organization}
                              onChange={handleChange}
                              className="p-inputtext-sm w-full"
                              required
                            />
                          </div>
                          <div className="field col-6 pr-0">
                            <label>Region</label>
                            <InputText
                              type="text"
                              name="region"
                              value={formData.region}
                              onChange={handleChange}
                              className="p-inputtext-sm w-full"
                              required
                            />
                          </div>
                        </div>
                        <div className="field col">
                          <label>How Can We Help You...</label>
                          <InputTextarea
                            name="message"
                            value={formData.message}
                            onChange={handleChange}
                            className="p-inputtext-sm w-full"
                            required
                          />
                        </div>

                        <div className="submit-btn">
                          <button type="submit">Submit</button>
                        </div>

                        {showSuccessMessage && (
                          <div className="success-messages">
                            <div className="success-message-contents">
                              <div className="success-message-headings">
                                Submitted Successfully !
                              </div>
                              <AiOutlineCheckCircle className="check-icon"></AiOutlineCheckCircle>
                            </div>
                          </div>
                        )}
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="contact-us-section-sales aln_Pos">
          <div className="pa_TB contact-us-section-sales-overlay">
            <div className="w-full max_Width flex">
              <div
                className="contact-us w-full flex g_25 flex_D_K"
                style={{ alignItems: "flex-start" }}
              >
                <div
                  className="contact-us-section-sales-content flex flex_D"
                  style={{ alignItems: "flex-start" }}
                >
                  <h2>Interested In Our Product?</h2>
                  <p className="contact-us-section-para">
                    Talk to our sales team.
                  </p>
                  <p className="contact-us-section-para">
                    <a
                      href="mailto:sales@althisolutions.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      sales@althisolutions.com
                    </a>
                  </p>
                  <p className="contact-us-section-para">
                    From questions about pricing to personalized one-on-one
                    demos, we’re here to help you get started.
                  </p>
                </div>
                <div
                  className="contact-us-section-sales-images"
                  style={{ minHeight: "400px" }}
                >
                  <div
                    className="w-full flex"
                    style={{ minHeight: "400px", alignItems: "flex-start" }}
                  >
                    <div class="arT_C">
                      <div className="content_Sec usa">
                        <button>USA</button>
                        <div className="sub_Content">
                          <div>1200 ford rd,</div>
                          <div>Farmers Branch,</div>
                          <div>Texas - 75234.</div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className="w-full flex"
                    style={{ minHeight: "400px", alignItems: "flex-end" }}
                  >
                    <div class="arT_C">
                      <div className="content_Sec india">
                        <button>India</button>
                        <div className="sub_Content">
                          <div>6/483 C,Fathima Road East,</div>
                          <div>Azhagappapuram,</div>
                          <div>Kanyakumari District,</div>
                          <div>Tamil Nadu - 629401.</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactUs;
