import React, { useState } from "react";
import "./ourservice.css";
import blueArrow from "../../Media/bluearrow.svg";
import retail from "../../Media/retail.jpg";
import education from "../../Media/education.jpg";
import healthcare from "../../Media/healthcare.jpg";
import realEstate from "../../Media/real estate.jpg";
import manufacturing from "../../Media/manufacturing.jpg";
import media from "../../Media/media.jpg";
import banking from "../../Media/banking.jpg";
import lifescience from "../../Media/lifescience.jpg";
import { GoChevronDown, GoChevronUp } from "react-icons/go";
import { useNavigate } from "react-router-dom";

const Ourservice = () => {
  const [showMore, setShowMore] = useState(false);
  const navigate = useNavigate();
  return (
    <section className="container-service">
      <div className="servicepage">
        <p className="heading2">Our Services</p>
        <p className="subheading2">
          We provide cutting-edge IT solutions tailored to your business needs.
        </p>
      </div>
      <div className="service-section">
        <div className="service-one">
          <img className="img-first" src={realEstate} alt="realEstate" />
          <p className="heading3">Real Estate</p>
          <p className="subheading3">
            Transforming property management with cutting-edge digital solutions
            for seamless transactions and enhanced customer experiences.
          </p>

          <button className="secondary" onClick={() => navigate("/contactus")}>
            <span className="testing">Know more</span>
            <img className="response-arrow" src={blueArrow} alt="" />
          </button>
        </div>
        <div className="service-two">
          <img className="img-second" src={education} alt="education" />
          <p className="heading4">Education</p>
          <p className="subheading4">
            Empowering learning with smart technology, making education more
            accessible, engaging, and effective.
          </p>

          <button className="secondary" onClick={() => navigate("/contactus")}>
            <span className="testing">Know more</span>
            <img className="response-arrow" src={blueArrow} alt="" />
          </button>
        </div>
        <div className="service-three">
          <img className="img-three" src={manufacturing} alt="manufacturing" />
          <p className="heading5">Manufacturing</p>
          <p className="subheading5">
            Driving efficiency and innovation through automation, IoT, and
            AI-powered solutions for the modern industry.
          </p>

          <button className="secondary" onClick={() => navigate("/contactus")}>
            <span className="testing">Know more</span>
            <img className="response-arrow" src={blueArrow} alt="" />
          </button>
        </div>
        <div className="service-four">
          <img className="img-four" src={media} alt="media" />
          <p className="heading6">Communication & Media</p>
          <p className="subheading6">
            Revolutionizing content delivery and audience engagement with
            advanced digital platforms and analytics.
          </p>

          <button className="secondary" onClick={() => navigate("/contactus")}>
            <span className="testing">Know more</span>
            <img className="response-arrow" src={blueArrow} alt="" />
          </button>
        </div>
      </div>
      <div className={`extra-services ${showMore ? "open" : ""}`}>
        <div className="service-five">
          <img className="img-five" src={healthcare} alt="healthcare" />
          <p className="heading7">Healthcare</p>
          <p className="subheading7">
            Enhancing patient care with secure, AI-driven healthcare solutions
            for better diagnostics and management.
          </p>
          <button className="secondary" onClick={() => navigate("/contactus")}>
            <span className="testing">Know more</span>
            <img className="response-arrow" src={blueArrow} alt="" />
          </button>
        </div>

        <div className="service-six">
          <img className="img-six" src={banking} alt="banking" />
          <p className="heading8">Banking</p>
          <p className="subheading8">
            Redefining financial services with secure, data-driven solutions for
            seamless transactions and customer-centric banking
          </p>
          <button className="secondary" onClick={() => navigate("/contactus")}>
            <span className="testing">Know more</span>
            <img className="response-arrow" src={blueArrow} alt="" />
          </button>
        </div>

        <div className="service-seven">
          <img className="img-seven" src={retail} alt="retail" />
          <p className="heading9">Retail</p>
          <p className="subheading9">
            Elevating shopping experiences with personalized, AI-powered
            insights and omnichannel commerce solutions.
          </p>
          <button className="secondary" onClick={() => navigate("/contactus")}>
            <span className="testing">Know more</span>
            <img className="response-arrow" src={blueArrow} alt="" />
          </button>
        </div>

        <div className="service-eight">
          <img className="img-eight" src={lifescience} alt="lifescience" />
          <p className="heading10">Life Science</p>
          <p className="subheading10">
            Accelerating innovation in pharmaceuticals and biotechnology with
            advanced research and data-driven solutions.
          </p>
          <button className="secondary" onClick={() => navigate("/contactus")}>
            <span className="testing">Know more</span>
            <img className="response-arrow" src={blueArrow} alt="" />
          </button>
        </div>
      </div>
      <button className="Primary-two" onClick={() => setShowMore(!showMore)}>
        <span className="testing">{showMore ? "Show Less" : "Show More"}</span>
        {showMore ? (
          <GoChevronUp className="response-blackarrow" />
        ) : (
          <GoChevronDown className="response-blackarrow" />
        )}
      </button>
    </section>
  );
};

export default Ourservice;
