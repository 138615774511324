import React, { useState, useEffect } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import "./nav.css";
import althiLogo from "../../Media/althilogo.svg";
import { GoGrabber } from "react-icons/go";
import { GoX } from "react-icons/go";
import { useLocation } from "react-router-dom";

gsap.registerPlugin(ScrollTrigger);

const Nav = () => {
  const location = useLocation();
  useEffect(() => {
    if (location.pathname === "/") {
      ScrollTrigger.create({
        trigger: ".hero",
        start: "bottom top",
        onEnterBack: () =>
          document.querySelector(".navbar").classList.remove("navbar-dark"),
        onLeave: () =>
          document.querySelector(".navbar").classList.add("navbar-dark"),
      });
    } else {
      document.querySelector(".navbar").classList.add("navbar-dark");
    }
  }, [location.pathname]);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  return (
    <div className="container-navbar">
      <header
        className={`navbar ${mobileMenuOpen ? "navbar-mobile-active" : ""}`}
      >
        <div className="logo">
          <a href="/" target="_self">
            <img src={althiLogo} alt="" />
          </a>
        </div>
        <nav className="navlinks">
          <div
            className="nav-item"
            onMouseEnter={() => setDropdownOpen(true)}
            onMouseLeave={() => setDropdownOpen(false)}
          >
            <a href="#">Products</a>
            {dropdownOpen && (
              <div className="dropdown">
                <a href="/whatWeDo/VCheck">Vcheck</a>
                <a href="/WhatWeDo/Acaia">Acaia</a>
                <a href="/whatWeDo/Exaan">Exaan</a>
              </div>
            )}
          </div>
          <div
            className="nav-item"
            onMouseEnter={() => setDropdownOpen(true)}
            onMouseLeave={() => setDropdownOpen(false)}
          >
            <a href="#">Services</a>
            {dropdownOpen && (
              <div className="dropdown">
                <a href="/contactus">Data Analytics</a>
                <a href="/contactus">Cyber Security</a>
                <a href="/contactus">Artificial Intelligence </a>
                <a href="/contactus">Sustainability Service </a>
              </div>
            )}
          </div>

          <a href="/whoWeAre">Who we are</a>
          <a href="/blog">Insights</a>
          <a href="/Careers">Careers</a>
          <a href="/contactus">Contact Us</a>
        </nav>
        <div
          className="hamburger-menu"
          onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
        >
          {mobileMenuOpen ? <GoX size={32} /> : <GoGrabber size={40} />}
        </div>

        <nav className={`mobile-nav ${mobileMenuOpen ? "show-menu" : ""}`}>
          <div className="mobile-nav-item">
            <a href="#">Products</a>
            <div className="mobile-dropdown">
              <a href="/whatWeDo/VCheck">Vcheck</a>
              <a href="/WhatWeDo/Acaia">Acaia</a>
              <a href="/whatWeDo/Exaan">Exaan</a>
            </div>
          </div>

          <div className="mobile-nav-item">
            <a href="#">Services</a>
            <div className="mobile-dropdown">
              <a href="/contactus">Data Analytics</a>
              <a href="/contactus">Cyber Security</a>
              <a href="/contactus">Artificial Intelligence</a>
              <a href="/contactus">Sustainability Service</a>
            </div>
          </div>

          <a href="/whoWeAre">Who we are</a>
          <a href="/blog">Insights</a>
          <a href="/Careers">Careers</a>
          <a href="/contactus">Contact Us</a>
        </nav>
      </header>
    </div>
  );
};

export default Nav;
