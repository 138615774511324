import React from "react";
import "./BlogBanner.scss";

const BlogBanner = () => {
  return (
    <>
      <div className="blog-page aln_Pos">
        <div className="pa_25_ blog-page-text">
          <div className="max_Width">
            <h2>
              Discover the Best Stories from Our Bloggers <span>!</span>
            </h2>
            <p>
              From cloud computing, and AI to the big data of the digital world,
              the trends evolve here. Stay updated, stay inspired!
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default BlogBanner;
