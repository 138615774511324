import React from "react";
import bg_Img from "../../Assets/WhoWeAre/sample.png";
import "./WhoWeAreBanner.scss";

const WhoWeAreBanner = () => {
  return (
    <>
      <div
        className="flex flex_D g_25 aln_Pos"
        style={{ background: "#ffffff" }}
      >
        <div className="w-full flex max_Width g_25 whoWe_AreBanner">
          <div className="leFt max_Width flex flex_D g_25 w-full">
            <h2
              className="w-full tiTTle flex text_B"
              style={{
                fontWeight: "lighter",
                height: "auto",
                justifyContent: "flex-start",
                padding: "0 25px",
              }}
            >
              Business is complicated,
              <br /> but we guarantee it <br />
              won't be boring
            </h2>
          </div>

          <div className="riGht">
            <img className="maP_" src={bg_Img} alt="" />
          </div>
        </div>
      </div>
    </>
  );
};

export default WhoWeAreBanner;
