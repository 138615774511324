import PrivacyTab from "./PrivacyTab/PrivacyTab";
import Nav from "../Home/navbar/nav";

const PrivacyPolicy = () => {
  return (
    <>
      <Nav />
      <PrivacyTab />
    </>
  );
};

export default PrivacyPolicy;
