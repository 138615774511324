import React, { useState, useEffect } from "react";
import { gsap } from "gsap";
import "./founder.css";
import facebook from "../../Media/facebook.svg";
import linkedin from "../../Media/linkedin.svg";
import twitter from "../../Media/twitter.svg";
import portrait from "../../Media/Wilson1.png";
import portraiTwo from "../../Media/Ashish1.png";
import founder from "../../Media/founder.png";

const foundersdetails = [
  {
    name: "Wilson Raj",
    details:
      "Is a visionary leader who drives innovation and digital transformation...",
    image: portrait,
  },
  {
    name: "Ashish",
    details: "Co-founder of the company with a vision to expand...",
    image: portraiTwo,
  },
];

const Founder = () => {
  const [currentFounder, setCurrentFounder] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentFounder((prev) => (prev === 0 ? 1 : 0)); // Toggle between 0 and 1
    }, 4000); // Change every 4 seconds

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    gsap.fromTo(
      ".founder-details",
      { opacity: 0, y: 50 },
      { opacity: 1, y: 0, duration: 1 }
    );
  }, [currentFounder]);
  return (
    <section className="founder-section">
      <div className="founder-details">
        <div className="founder-content">
          <div className="founder-about">
            <p className="wilson">{foundersdetails[currentFounder].name}</p>
          </div>
          <div className="founder-about-two">
            <p className="wilson-details">
              {foundersdetails[currentFounder].details}
            </p>
          </div>

          <div className="socialmedia-icons">
            <div className="fb-icon">
              <a href="https://www.facebook.com/AlthiSolutions">
                <img
                  className="social-media-icon"
                  src={facebook}
                  alt="facebook"
                />
              </a>
            </div>
            <div className="x-icon">
              <a href="https://www.instagram.com/althi_solutions/">
                <img
                  className="social-media-icon"
                  src={twitter}
                  alt="instagram"
                />
              </a>
            </div>
            <div className="linkedin-icon">
              <a href="https://in.linkedin.com/company/althisolutions">
                <img
                  className="social-media-icon"
                  src={linkedin}
                  alt="linkedin"
                />
              </a>
            </div>
          </div>
        </div>
        <div className="founder-image">
          <img
            className="bw-image"
            src={foundersdetails[currentFounder].image}
            alt="founder and cofounder"
          />
          <div className="founder-rotate">
            <img className="tag-rotate" src={founder} alt="founder tag" />
          </div>
          {/* <div className="blue-circle"></div> */}
        </div>
      </div>
    </section>
  );
};

export default Founder;
