import React from "react";
import map from "../../Assets/WhoWeAre/map.png";

const OurPresence = () => {
  return (
    <>
      <div className="w-full aln_Pos pa_TB" style={{ background: "#F5F5F5" }}>
        <div className="w-full max_Width flex flex_D g_25">
          <div className="max_Width flex flex_D g_25 ">
            <h2 className="w-full tiTTle flex text_B">Our Presence</h2>
            <div className="ti_SubTTle">
              Our service areas include USA, UK, Middle East, India, and Africa.
              Once you partner with us, your goals become our goals. Our job has
              always been to meet your needs and keep your operations running
              smoothly.
            </div>
          </div>

          <img
            className="maP_"
            src={map}
            alt=""
            style={{
              width: "-webkit-fill-available",
              height: "500px",
            }}
          />
        </div>
      </div>
    </>
  );
};

export default OurPresence;
